import { faMoneyCheckDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const InfoDonate = () => {
  return (
    <div className="flex flex-col gap-2">
      <header className="flex items-center gap-1">
        <FontAwesomeIcon icon={faMoneyCheckDollar} size="2xs" className="pt-0.5" />
        <h2 className="text">Donating</h2>
      </header>
      <section>
        <p>Donating keeps this site fast, free, and defended against antis. <a href="https://freak.university/donate.html">More info</a></p>
      </section>
    </div>
  )
};