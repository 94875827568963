import { faGavel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const InfoRules = () => {
  return (
    <div className="flex flex-col gap-2">
      <header className="flex items-center gap-1">
        <FontAwesomeIcon icon={faGavel} size="2xs" className="pt-0.5" />
        <h2 className="text">Rules</h2>
      </header>
      <section>
        <p>You must follow the <a href="https://freak.university/rules.html">Freak University rules</a> at all times.</p>
      </section>
    </div>
  )
};