import { InfoPrivacy } from "./InfoPrivacy";
import { InfoRules } from "./InfoRules";
import { InfoWelcome } from "./InfoWelcome";
import { InfoDonate } from "./InfoDonate";

export const InfoText = () => {
  return (
    <div className="flex flex-col p-5 py-1 gap-2">
      <InfoWelcome />
      <InfoRules />
      <InfoPrivacy />
      <InfoDonate />
    </div>
  )
};

